<template lang="pug">
.i-wrap(style="width:100%")
  transition(name="slideup")
    .mtes-wrap(style='width:100%')
      .mtes-tab-wrap
        #overview.pointer(v-bind:class="[{active: tab =='overview'}, {active: tab ==undefined}]" @click="tabChange($event)") 總覽
        #tabCast.pointer(v-bind:class="{active: tab =='tabCast'}" @click="tabChange($event)") 演員 
        //- #tabCast.pointer(v-bind:class="{active: tab =='tabCast'}" @click="tabChange($event)") 演員 / 工作人員
        #tabReviews.pointer(v-bind:class="{active: tab =='tabReviews'}" @click="tabChange($event)") 評論
      
      //- OVERVIEW TAB
      .mtes-mid(v-if="tab =='overview' || tab == undefined ")
        .mtes-mid-left
          .mtes-user-review-wrap(v-if="model == 'Movie' || model == 'TvEpisode'")
            Review(
              :id="mtes._id"
              :type="mtes.model"
            )
          .mtes-reviews-wrap(v-if="model == 'Movie' || model == 'TvEpisode'")
            h3 評論
            div(v-if="criticReviews.length > 0")
              .mtes-reviews-card( v-for="review in criticReviews")
                mtesReviewCard(
                  :review="review"
                )
            div(v-if="userReviews.length > 0")
              .mtes-reviews-card( v-for="review in userReviews")
                mtesReviewCard(
                  :review="review"
                )
            div(v-if="criticReviews.length == 0 && userReviews.length == 0") 沒有評論
        .mtes-mid-right
          mtesList(
            :items="cast"
            :listName="'演員列表'"
            :type="`star`"
            :showLessNumber="5"
          )
          #tabCast.pointer.see-more-list(@click="tabChange($event)") 更多

      //- CAST / CREW TAB
      .mtes-mid(v-if="tab =='tabCast'")
        .mtes-mid-left.full
          mtesCast(
            :type="`movie`"
            :id="`${id}`"
          )
        //- .mtes-mid-right
          mtesCrew(
            :type="'movie'"
            :id="`${id}`"
          )
      
      //- REVIEW TAB
      .mtes-mid(v-if="tab =='tabReviews' ")
        .mtes-mid-left
          mtesReviewList(
            :critic="false"
            :userOrCritic="`user`"
            :type="`movie`"
            :id="id"
          )
        .mtes-mid-right
          mtesReviewList(
            :critic="true"
            :userOrCritic="`critic`"
            :type="`movie`"
            :id="id"
          )

          //- .userAveScore
 
                .director(v-if="directors.length > 0") 導演:
                  span(v-for="director in directors")   
                    router-link(v-bind:to="`/star/${director.star._id}`")           
                      span(v-if="director.star.nameCH") {{director.star.nameCH}}
                      span(v-else) {{director.star.nameEN}}
                .writer(v-if="writers.length > 0") 編劇:
                  span(v-for="writer in writers")
                    router-link(v-bind:to="`/star/${writer.star._id}`")           
                      span(v-if="writer.star.nameCH") {{writer.star.nameCH}}
                      span(v-else) {{writer.star.nameEN}}              
                .producer(v-if="producers.length > 0") 製片人:
                  span(v-for="producer in producers")
                    router-link(v-bind:to="`/star/${producer.star._id}`")           
                      span(v-if="producer.star.nameCH") {{producer.star.nameCH}}
                      span(v-else) {{producer.star.nameEN}}

                Review(
                  :id="mtes._id"
                  :type="mtes.model"
                )
        
            
        //- .movieBot
 
          
</template>

<script>
import Review from '@/components/Review'
import MovieFullCast from '@/components/movie/MovieFullCast'
import Spinner from 'vue-simple-spinner'
import VClamp from 'vue-clamp'
import mtesDetails from '@/components/mtesDetails'
import mtesList from '@/components/mtesList'
import mtesCast from '@/components/mtesCast'
// import mtesCrew from '@/components/mtesCrew'
import mtesReviewList from '@/components/mtesReviewList'
import mtesReviewCard from '@/components/mtesReviewCard'

export default {
  name: 'MovieMid',
  components:{
    Review,
    Spinner,
    MovieFullCast,
    VClamp,
    mtesList,
    mtesDetails,
    mtesCast,
    // mtesCrew,
    mtesReviewCard,
    mtesReviewList,
  },
  props:{
    id:String,
    mtes:Object,
    reviews:Array,
    model:String,
    activeTab:String,
    cast:null,
    crew:null,
    
    criticReviews:null,
    userReviews:null,
    dirWriPro:null,
    directors:null,
    writers:null,
    producers:null
  },  
  data(){
    return{
      tab:null,
      lines: 3,
      fullCast:null,
      fullCrew:null,
      
      // activeTab:'overview'
      // selfReview:null,
    }
  },
  metaInfo() {
    var x = this.isLoading;
    var theName = '';
    if (!x){
      if (this.mtes.nameCH){
        theName = this.mtes.nameCH
      }else{
        theName = this.mtes.nameEN
      }
    }
    return {
      // title: this.isLoading ? 'Loading...': theName,
      // titleTemplate: "%s - 臭豆腐電影"
    }
  },
  methods:{
    toggleClamp(){
      this.lines > 0 ? this.lines = 0 : this.lines = 3;
      this.movieSummary = !this.movieSummary;
    },
    tabChange(event){
      this.tab = event.target.id
      // this.$router.replace({path:`/movie/${this.id}/${this.activeTab}`})
      // this[event.target.id]()
      // console.log(this.$route.params.activeTab)
      history.pushState(
        {},
        null,
        `/movie/${this.id}/${this.tab}`
      )
      this.$emit('updateTab',this.tab)
      // this.$router.push({name:`Movie`,params:{activeTab:`${this.tab}`,id:`${this.id}`}}, )
    },
    
    async movieInit(){
      this.tab = this.activeTab;
    }
  },
  async created(){
    this.model = this.$route.name;
    
    await this.movieInit();
    
  }
}
</script>
<style lang="scss" scoped>
  .mtes-wrap{
    display:flex;
    flex-direction: column;
  }
  .mtes-tab-wrap{
    margin:20px 0;
    display:flex;
    justify-content: center;
    div{
      background:transparent;
      margin:0 10px;
      border:solid 1px lightgrey;
      padding:1px 20px;
      border-radius: 3px;
      transition: background .3s, border .3s;
      &.active{
        background:white;
        border:solid 1px grey;
      }
    }
  }
  .mtes-top{
    width:100%;
    max-width:1400px;
    margin:20px auto 0px auto;
    padding:0 10px;
    display:flex;
    flex-wrap: wrap;
    // color:white;
    .mtes-top-left{
      // width:40%;
      max-width:250px;
    }
    .mtes-top-right{
      max-width:calc(100% - 250px);
      display:flex;
      flex-direction: column;
      padding:0 15px;

      .mtes-title{
        width:100%;
        h1{
          font-size: 32px;
          margin-bottom: 16px;
        }
        h4{
          font-size:24px;
          font-weight:100;
        }
      }
      .mtes-info{
        margin:10px 0;
      }
      .mtes-summary{
        margin-top:20px;
      }
    }

    .castListWrap{
      padding:10px;
      background:transparent;
      border-radius:10px;
    }
    .poster{      
      transition:transform .3s;
      img{
        width:100%;
      box-shadow:0 12px 19.2px -7.2px rgb(5 5 5 / 30%);
      }
    }
  }
  .mtes-mid{
    margin-top:20px;
    display:flex;
    justify-content: space-between;
    .mtes-mid-left,.mtes-mid-right{
      width:49%;
      border:1px solid #e6e6e6;
      border-radius:5px;
      padding: 20px;
      background:white;
      // box-shadow:0 12px 19.2px -7.2px rgb(5 5 5 / 20%);
    }
    .mtes-mid-left{
      .mtes-user-review-wrap{
        border-bottom:1px solid #e6e6e6;

      }
      .mtes-reviews-wrap{
        display:flex;
        flex-direction: column;
        
      }
      &.full{
        width:100%;
      }
    }
    .mtes-mid-right{
    }
  }
  .mtes-mid-whole{
    width:100%;
    border:1px solid #e6e6e6;
      border-radius:5px;
      padding: 20px;
      background:white;
  }
 

.scoreSummaryWrap{
  width:100%;
  color:black;
  margin-top:20px;

  .criticAveScore, .userAveScore{
    width:64px;
    height:64px;
    // margin:10px 0;
    border-radius:10px;
    position:relative;
    font-size:40px;
    font-weight:700;
    
    display:flex;
    align-items: center;
    justify-content: center;
    align-items: center;
    .outOf{
      font-size:12px;
    }
    
  }    
  .criticAveScore::after, .userAveScore::after{
    content: "";
    // background-image:url('../assets/img/awakescore1.png');
    background-color:#e6e6e6;
    border-radius:5px;
    background-size:cover;
    opacity: 0.9;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    position: absolute;
    z-index: -1;   
  }
}

@media screen and (max-width:1024px) {
  .mtes-mid{
    flex-direction: column;
  
    .mtes-mid-left, .mtes-mid-right{
      width:100%;
      margin:10px 0;
    }
  }
  
}
@media screen and (max-width:425px) {
  .mtes-tab-wrap{
    margin:20px 0;
    display:flex;
    justify-content: center;
    div{
      background:transparent;
      margin:0 10px;
      border:solid 1px lightgrey;
      padding:1px 5px;
      border-radius: 3px;
      transition: background .3s, border .3s;
      font-size:14px;
      &.active{
        background:white;
        border:solid 1px grey;
      }
    }
  }
}
  
 
  
  
</style>
